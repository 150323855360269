import React from 'react';
import { Link } from 'gatsby';
import Image from './Image';

const ListBrand = () => (
      <>
        <div className="brand-list">
          <h1>
            <Link className="md:hover:opacity-70 duration-300" to="/">
              <Image filename="logo/andersen.png" width={160} alt="Andersen Stove" />
            </Link>
          </h1>
          <div className="sub-brand">
            <Link className="md:hover:opacity-70 duration-300" to="/brand/morso/">
              <Image filename="logo/morso.png" width={80} alt="morso" />
            </Link>
            <Link className="md:hover:opacity-70 duration-300" to="/brand/leda/">
              <Image filename="logo/leda.png" className="md:ml-6 ml-2" width={80} alt="LEDA" />
            </Link>
          </div>
        </div>
      </>
  );

export default ListBrand;
