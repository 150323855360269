import React, { useState } from 'react';

const Accordion = (props) => {
  const {title, children} = props;
  const [isToggle, setIsToggle] = useState(true);
  return (
      <div className="accordion">
        <button
            className="accordion-title"
            onClick={() => setIsToggle((toggle) => !toggle)} aria-expanded={!isToggle}
            type="button"
        >
          <p>{title}</p>
        </button>
        <div className="accordion-content" aria-expanded={!isToggle}>
          <div className="accordion-inner">
            {children}
          </div>
        </div>
      </div>
  );
};
export default Accordion;
