import React from 'react';
import { Link } from 'gatsby';

const ListLink = () => (
      <>
        <ul className="link-list">
          <li>
            <Link className="hover-link" to="/woodstove/">薪ストーブとは</Link>
          </li>
          <li>
            <Link className="hover-link" to="/beginner/">初めての方へ</Link>
          </li>
          <li>
            <Link className="hover-link" to="/brand/">ブランド</Link>
          </li>
          <li>
            <Link className="hover-link" to="/chimney/">煙突</Link>
          </li>
          <li>
            <Link className="hover-link" to="/accessory/">アクセサリ</Link>
          </li>
          <li>
            <Link className="hover-link" to="/works/">導入事例</Link>
          </li>
          <li>
            <Link className="hover-link" to="/movie/">MOVIE</Link>
          </li>
          <li>
            <Link className="hover-link" to="/agencies/">正規代理店</Link>
          </li>
        </ul>
      </>
  );

export default ListLink;
