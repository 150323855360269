import  React from 'react';
import { Link } from 'gatsby';
import {Link as GoLink} from 'react-scroll/modules';
import Image from '../components/Image';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import Accordion from '../components/Accordion';

const FaqPage = () => (
      <Layout page="faq">
        <Seo title="Q&A"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="main-title"><span>Q</span><span>&</span><span>A</span></h2>
        </section>
        <section className="page-content flex-layout">
          <div className="main-content">
            <div className="content-block">
              <h3 className="sub-title"><span>よくある質問</span></h3>
              <p>アンデルセンストーブ製品について、お客様からよくいただく質問をご紹介します。<br />
                ご質問をクリックしますと、回答が表示されます。</p>
            </div>
            <div id="before" className="content-block">
              <h3 className="sub-title">BEFORE<span>購入前に多いご質問</span></h3>
              <Accordion title="ストーブを選ぶには何を基準に選ぶべきでしょうか？">
                <p>
                  本体のデザイン、機能性、大きさ、暖房能力、価格など様々です。<br />
                  詳しくは「<Link className="c-attention" to="/beginner/">初めての方へ</Link>」をご覧下さい。
                </p>
              </Accordion>
              <Accordion title="鋳鉄製のストーブとスチール製のストーブの違いって？">
                <p>
                  日本でも古くから馴染みの深いストーブで、天板、側板などの躯体構成部品はすべて鋳型を作った上で鋳造されています。鋳物は蓄熱性に優れており、破損や焼損をした場合にも、部品の交換が出来る反面、鋳型の鋳造には多額のコストを要するため、モデル数は限定されます。<br />
                  一方、スチールストーブは鋼板をコンピューター操作のレーザー光線によって切断されますが、溶接や組み立てには熟練工の手仕事で1台1台丁寧に作られます。多様なデザインが可能で、インテリアデザインとして楽しめる特徴を持ち、フリースタンディングストーブとも呼ばれています。
                </p>
              </Accordion>
              <Accordion title="ソープストーンやサンドストーンって何ですか？">
                <p>
                  装飾性と蓄熱性に優れた、ストーブ本体の両サイド及びまたはトッププレートの上に装着する天然石です。
                </p>
              </Accordion>
              <Accordion title="輻射熱方式と対流熱方式の違いは？">
                <p>
                  輻射熱式とは燃焼室内で発生した熱が直接伝わるタイプです。ストーブの躯体外壁部分から暖まり、遠赤外線効果によって外壁部分表面に接する空気を直接暖め、ストーブ本体の周辺から部屋全体を暖めるのが輻射熱式です。そのため、ストーブから放射される暖かさが肌に直接感じられます。また、熱伝導が早く、全方向に放熱し、冷めにくい特徴を持っており、屋内スペース全体を暖めるのに適しています。<br />
                  対流熱式とはストーブ躯体外壁部分に空気流通層を設け、下部より冷たい空気を吸い込み、上部から暖まった空気を放出し部屋全体を速やかに暖めます。この場合でも、空気流通層を設けていないストーブ天板部分と前面部分は一部のモデルを除き、輻射熱が放出されます。対流熱式は側面部分や背面部分にコンベクション（対流）プレートを取り付けることによって空気流通層を設ける二重構造です（一部モデルを除く）。そのため、コンベクション（対流）プレート表面部分は比較的高温になりませんので、壁や家具により近付けて設置することができ、高齢者や小さなお子様のいるご家庭では安全性が高いというメリットがあります。着火後、部屋が暖まる時間は輻射熱式と比べ対流熱式がやや早く、屋内スペースで、区画割りされた部屋を暖めるのに適しています。
                </p>
              </Accordion>
              <Accordion title="ビルトインストーブの魅力って一体何ですか？">
                <p>
                  ビルトインタイプのストーブはリビングルームなどの壁面部に組み込まれ、マントルピースや煉瓦、石、タイル、などお好みの造作手法によって装飾されたスペースで、ストーブ本来の持つ、揺らめく暖かな炎を創り出すだけでなく、様々なインテリアイメージとストーブライフを自由に演出させることができるというところが大きな魅力です。また、シーズンオフの間は壁の中にユニット本体が組み込まれている為、通行の邪魔にもなりません。
                </p>
              </Accordion>
              <Accordion title="薪ストーブって、地球温暖化現象に関与しているものなのでしょうか？">
                <p>
                  木を燃やすとCo2を排出しますが、その排出量は樹木が成長過程において、光合成によって大気から吸収したCo2とほぼ同量です。しかも、その燃焼が効率良く行われるとCo2の量は更に減少します。つまり、樹木を切り倒し、薪を作り、効率良く燃やして発生したCo2は樹木が育つ間に吸収される自然のサイクルが、むしろ負担をかけないと言えるのです。化石燃料とは異なり、植林によって再生することができる唯一の資源である薪を燃料として使用している薪ストーブはむしろ地球環境を守ることの大切さが叫ばれている現在、注目を浴びています。
                </p>
              </Accordion>
              <Accordion title="クリーンバーン燃焼方式って？">
                <p>
                  薪燃焼で発生する煙に含まれるCO,タール、微粒子などの不純物を再度燃焼する、いわゆる二次燃焼をより高める機能をクリーンバーン（CB）といいます。アンデルセンストーブでは触媒（キャタリティックコンバスター）を採用せず、ストーブ本体の構造を進化させる方法でクリーンバーン（CB）を実現しました。これにより、より高度な燃焼が行われ、高い燃焼効率とともに、クリーンな排気を実現しました。また、薪の経済的な消費と煙突内に付着するクレオソートやタールを減少させ、メンテナンスを軽減します。
                </p>
              </Accordion>
              <Accordion title="アスベストは使用されていますか？">
                <p>
                  「アンデルセンストーブ」や「アンデルセンチムニー」に使用されている断熱材やガスケット類を含めた構成部品にはアスベストは一切使用されていません。
                </p>
              </Accordion>
              <Accordion title="既存の建物をリフォームする際に薪ストーブを設置したいのですが？">
                <p>
                  ストーブ本体の設置、及び煙突の配管プランの内容、その他によって、設置ができない場合もあります。最寄りの弊社販売代理店へご相談下さい。
                </p>
              </Accordion>
              <Accordion title="薪ストーブ本体と煙道部材を自分で購入して、本体及び煙突の設置工事を自分でやりたいのですが？">
                <p>
                  配管設備を伴ない、建物と付随した火を取り扱う暖房設備機器である商品の性格上、弊社では、責任施工を前提とした販売代理店制度に基づいた商品の供給販売をとっている為、部材のみの販売は一切承っておりません。専門知識と施工実績のある最寄りの販売代理店による材料・工事一式での購入をお薦めします。
                </p>
              </Accordion>
              <Accordion title="現在、薪ストーブを使用していますが、既存の煙突を生かし、ストーブ本体のみを買い替えたいのですが？">
                <p>
                  煙突の程度や規格、仕様、口径寸法の他に、薪ストーブ本体の口径寸法などによって判断されるので、最寄りの弊社販売代理店までご相談下さい。
                </p>
              </Accordion>
              <Accordion title="薪ストーブ本体の価格よりも、施工費用等を含めた煙道部材費のほうが割高であるとよく耳にしますが・・・？">
                <p>
                  設置プランによる二重断熱煙道部材とシングル煙突との比率や二重断熱煙道部材のエルボーやT字筒などの点数、 角型ルーバートップなど比較的単価の高い部材を使用することによって、煙道部材全体とストーブ本体との金額差に開きが生じます。また、工事を行う弊社施工販売店もアフターメンテナンスの対応も含めた、責任施工体制で工事を請け負っている為、施工関係費用もそれに応じた金額となります。
                </p>
              </Accordion>
              <Accordion title="床面の保護について？">
                <p>
                  アンデルセンストーブ（＊短脚仕様を除く）は床面に熱を伝えにくい構造となっている為、土間打ち仕上げや、ブロック・煉瓦等の不燃ステージなどのような、耐火・耐熱処理を目的とした施工の必要性がありません。煤や火の粉からフローリングやカーペットの表面を保護する目的で、スチールやガラス等の素材を敷き詰めるだけで十分な為、シンプル性やコストパフォーマンスの面においても優れています。
                </p>
              </Accordion>
              <Accordion title="薪ストーブの寿命は一般的にどのくらいでしょうか？また、保証期間などってありますか？">
                <p>
                  ストーブの保証期間は5年となっておりますが、正しい薪の選択と取扱い説明書に基づいた正しい使用方法を守り、ガスケットや耐火煉瓦、ドアガラス等のような消耗部品の交換など、こまめなメンテナンスを行うことによって、末永くご使用することができます。
                </p>
              </Accordion>
              <Accordion title="燃料として使用する薪はどの樹種が適しているのでしょうか？また、何処でどのように入手すればいいのでしょうか？">
                <p>
                 詳しくは<Link to="/beginner/#about">「薪について」</Link>をご覧下さい。
                </p>
              </Accordion>
              <Accordion title="ウッドコンパーメントスぺ―スに薪を置いても大丈夫ですか？">
                <p>
                  アンデルセンストーブの脚長は25㎝以上あり（*短脚仕様を除く）、本体燃焼室の下部にはボトムシールドがさらに装備されているので、高温を帯びる心配はございません。屋外の薪置き場から屋内に運び入れた薪の最終保管場所として最適です。
                </p>
              </Accordion>
              <Accordion title="小さな子供がいるので、不意に接触して火傷や怪我をさせたくないのですが？">
                <p>
                  お子様が誤って手を触れても把手が高温にならない「コールドレバーハンドル」や、不用意に扉を開けないように、開閉ハンドルを取り外し式に出来る「チャイルド・セーフ・ドアーハンドル」などの機能を装備したモデルを用意しております。また、薪ストーブが発する熱の怖さが理解されるまで、アクセサリの「ハースゲート」を設置する方法もございます。
                </p>
              </Accordion>
              <Accordion title="薪ストーブを使用中に燃焼室内の温度や薪から発する炎によって、ドアーガラスが割れたりしませんか？">
                <p>
                  アンデルセンストーブに使用しているドアーガラスは耐熱性に優れたガラスを使用しておりますが、ガラス面に薪を直接凭れさせて焚いたり、薪のくべ過ぎによるオーバーバーニングや急激な給気操作などを行わない様十分注意して下さい。
                </p>
              </Accordion>
              <Accordion title="薪ストーブを使って、ちょっとした料理を楽しみたいのですが？">
                <p>
                  背面出しキット（*一部のモデルにオプション設定）を用いて、開放されたトップスペースにフライパンや鍋等を置いたり、熾き火状態になった燃焼室内に五徳を介してピザパンやダッチオーブンを置いた、色々な料理が楽しめます。
                </p>
              </Accordion>

            </div>

            <div id="after" className="content-block">
              <h3 className="sub-title">AFTER<span>購入後に多いご質問</span></h3>
              <Accordion title="薪の他に、紙やダンボールなどを燃やしてもいいですか？">
                <p>
                  <p>
                    大量に使用するとストーブ本体と煙道部材にダメージを与えるばかりでなく臭いや煤の原因にもなりかねますので、着火剤としてのみ使用して下さい。<br />＊弊社ではパラフィンを原料とした「フラムゴ着火剤」やパイン材おが粉と植物油を原料とした「ファイヤーアップ」の着火剤を用意しております。
                  </p>
                </p>
              </Accordion>
              <Accordion title="薪が着火しにくい。">
                <p>
                  <span className="font-bold">湿った薪を使用していませんか？</span><br />
                  十分に乾燥させた薪を使用して下さい。
                </p>
                <p>
                  <span className="font-bold">枝木や細かい薪を用いながら火を起こしていますか？</span><br />
                  いきなり太い薪をくべずに、枝木や細い薪から徐々に焚き慣らしながら太い薪をくべ足して下さい。
                </p>
                <p>
                  <span className="font-bold">給気調整口が閉じていませんか？</span><br />
                  煙突にダンパーが装備されている場合はダンパーを全開にし、一次給気口が装備されていないモデルはドアーを僅かに開きながら、2次給気口を全開にして下さい。また、給気口付近に埃やゴミ、ペットの毛等が付着している場合があります。給気機能を損ねない様、速やかに取り除いて下さい。
                </p>
                <p>
                  <span className="font-bold">煙道内は十分に暖まっていますか？</span><br />
                  煙道内が冷え切っていると排気に必要なドラフトが確保できません。着火剤を使用し、ドアーを僅かに開くことによって煙道内を暖めて下さい。
                </p>
                <p>
                  <span className="font-bold">定期的に煙道内の点検を行っていますか？</span><br />
                  煙道内に煤や鳥の巣などの異物が詰まっていたりすることがあります。煙道内の点検を兼ねた煙突掃除を行って下さい。
                </p>
                <p>
                  <span className="font-bold">ストーブ本体の近くに換気扇はありますか？</span><br />
                  換気扇を使用中に薪ストーブを焚くと煙道内のドラフトが減少します。換気扇を止めた状態で焚かれるか、または窓を少し開けた状態で焚いて下さい。
                </p>
              </Accordion>
              <Accordion  title="薪の燃焼時間が早く、燃料消費量が増えている。">
                <p><span className="font-bold">十分乾燥させた太い薪を使用していますか？</span><br />
                  枝木や細かい薪を用いながら火を起こし、燃焼温度が十分に上昇したら、徐々に太い薪をくべて下さい。大きな薪は小さな薪より長く燃焼します。</p>
                <p><span className="font-bold">給気調整レバーの位置は？</span><br />
                  焚き付けや追い焚き時に開く一次給気口は安定燃焼状態になったら、閉じて下さい。また、二次給気口を絞り火力を調整して下さい。ただし絞り過ぎは不完全燃焼の原因となりますのでご注意下さい。更に、灰受け収納ボックス専用扉を装備しているモデルにおいては、ドアを完全に閉じて焚いて下さい。ドアを開けたまま焚き続けると大量の空気を取り込み、薪の燃焼を過剰に促進させてしまい、目皿や目皿受け等の消耗部品の交換頻度を速めてしまいます。</p>
                <p><span className="font-bold">ストーブの気密性は十分保たれていますか？</span><br />
                  ガスケットの摩耗・磨滅や本体及びまたはドアーの歪み（＊特にスチールストーブ）によって発生した隙間より空気を取り込んだ結果、必要以上に余分な燃焼をもたらす結果となります。ガスケットの交換をはじめとする点検・メンテナンスをお薦めします。詳しくは弊社販売代理店へ。</p>
                <p><span className="font-bold">ドラフトが効き過ぎていませんか？若しくは煙突メーカーが定めている最大高を超えていませんか？</span><br />
                  強すぎるドラフトによって過剰燃焼を助長させる結果に繋がります。煙突にダンパーを装備することも可能ですが、購入された販売代理店にご相談下さい。</p>
              </Accordion>
              <Accordion  title="本体から煙が逆流する。">
                <p><span className="font-bold">湿った薪を使用していませんか？</span><br />
                  十分に乾燥させた薪を使用して下さい。</p>
                <p><span className="font-bold">ストーブ本体の近くに換気扇はありますか？</span><br />
                  換気扇を使用中に薪ストーブを焚くと煙道内のドラフトが減少して逆流が発生するケースがあります。換気扇を止めた状態で焚かれるか、または窓を少し開けた状態で焚いて下さい。</p>
                <p><span className="font-bold">煙突に付いているダンパーは開いていますか？</span><br />
                  ダンパー機能付の煙突が付いている場合、ダンパーが閉まったままの状態で焚いていると、煙が逆流します。</p>
                <p><span className="font-bold">煙道部材の正しい配管方法が守れていますか？</span><br />
                  煙突の曲がりの箇所が多かったり、過剰な横引き、最低煙突高がメーカーの定めている数値を満たしていな場合、更に本体のカップリングに接続する煙突の内径が適正でない場合は煙が本体から逆流してしまい、ストーブ本来が持つ潜在能力をフルに発揮することが不可能となってしまします。
                </p>
                <p><span className="font-bold">煙道内は十分に暖まっていますか？</span><br />
                  煙道内が冷え切っていると排気に必要なドラフトが確保できません。着火剤を使用し、ドアーを僅かに開いくことによって煙道内を暖めて下さい。
                </p>
                <p><span className="font-bold">定期的に煙道内の点検を行っていますか？</span><br />
                  煙道内に煤や鳥の巣などの異物が詰まっていたりすることがあります。煙道内の点検を兼ねた煙突掃除を行って下さい。
                </p>
                <p><span className="font-bold">強風の中でストーブを焚いていますか？</span><br />
                  強風によって正常なドラフトが得られず煙が逆流する場合があります。煙突トップの仕様変更や煙突を追加することによって改善することができます。購入された販売代理店にご相談下さい。
                </p>
              </Accordion>
              <Accordion  title="室内の温度がなかなか上昇しない。">
                <p><span className="font-bold">湿った薪を使用していませんか？</span><br />
                  十分に乾燥させた薪を使用して下さい。</p>
                <p><span className="font-bold">適切な薪の量を投入していますか？</span><br />
                  手首位の太さから二の腕程度の太さの薪を２～３本くべて下さい。
                </p>
                <p><span className="font-bold">二次給気口調整レバーの位置は？</span><br />
                  二次給気口を絞り過ぎているようでしたら、全開にして下さい。
                </p>
                <p><span className="font-bold">ストーブ本体の大きさは？</span><br />
                  暖めようとしているお部屋の容積に対してストーブの暖房能力が不足しているケースも考えられます。
                </p>
                <p><span className="font-bold">ドラフトが効き過ぎていませんか？若しくは煙突メーカーが定めている最大高を超えていませんか？</span><br />
                  強すぎるドラフトによって燃焼によって得られた熱が過剰に吸い上げられてしまいます。煙突にダンパーを装備することも可能です。購入された販売代理店にご相談下さい。
                </p>
                <p><span className="font-bold">ストーブを設置した建物は？</span><br />
                  建物の立地条件や気象風土、構造や気密・断熱性能が起因する場合も考えられます。
                </p>
              </Accordion>
              <Accordion  title="ガラスがひどく曇ったり、燃焼室内の耐火煉瓦等が煤ける。">
                <p><span className="font-bold">湿った薪を使用していませんか？</span><br />
                  十分に乾燥させた薪を使用して下さい。</p>
                <p><span className="font-bold">枝木や細かい薪を用いながら火を起こしていますか？</span><br />
                  いきなり太い薪をくべずに、枝木や細い薪から徐々に焚き慣らしながら太い薪をくべ足して下さい。
                </p>
                <p><span className="font-bold">適正な薪の量を投入していますか？</span><br />
                  手首位の太さから二の腕程度の太さの薪を２～３本くべて下さい。
                </p>
                <p><span className="font-bold">給気調整口が閉じていませんか？</span><br />
                  煙突にダンパーが装備されている場合はダンパーを全開にし、一次給気口が装備されていないモデルはドアーを僅かに開きながら、2次給気口を全開にして下さい。また、給気口付近に埃やゴミ、ペットの毛等が付着している場合があります。給気機能を損ねない様、速やかに取り除いて下さい。
                </p>
                <p><span className="font-bold">ストーブの気密性は十分に保たれていますか？</span><br />
                  ガスケットの摩耗・磨滅や本体及びまたはドアーの歪み（＊特にスチールストーブ）によって発生した隙間より空気を取り込んだ結果、必要以上に余分な燃焼をもたらす結果となります。ガスケットの交換をはじめとする点検・メンテナンスをお薦めします。詳しくは弊社販売代理店へ。
                </p>
              </Accordion>
              <Accordion  title="煙突の内がよく詰まる。">
                <p><span className="font-bold">湿った薪を使用していませんか？</span><br />
                  十分に乾燥させた薪を使用して下さい。</p>
                <p><span className="font-bold">給気調整口を絞った状態で焚き続けていませんか？</span><br />
                  低温度の状態で使用し続けると、不完全燃焼によるクレオソートやタール、煤等が煙道内に蓄積されやすくなり、煙道内火災の原因にもなります。定期的に、二次給気口を全開にした状態で焚いて頂くよう心がけて下さい。
                </p>
                <p><span className="font-bold">ストーブの気密性は十分に保たれていますか？</span><br />
                  ガスケットの摩耗・磨滅や本体及びまたはドアーの歪み（＊特にスチールストーブ）によって発生した隙間より空気を取り込んだ結果、必要以上に余分な燃焼をもたらす結果となります。ガスケットの交換をはじめとする点検・メンテナンスをお薦めします。詳しくは弊社販売代理店へ。
                </p>
                <p><span className="font-bold">煙道部材の正しい配管方法が守れていますか？</span><br />
                  煙突の曲がりの箇所が多かったり、過剰な横引き、最低煙突高がメーカーの定めている数値を満たしていな場合や、更に本体のカップリングに接続する煙突の内径が適正でない場合は適正なドラフトが得られずに、煙道の温度が上昇せずに、煤やクレオソートが溜まりやすくなり、煙道内火災の原因にも繋がります。詳しくは弊社販売代理店へ。
                </p>
              </Accordion>
              <Accordion  title="灰の処理方法は？">
                <p>灰受け皿があれば、トレイごと取り出すかまたは、灰取り用シャベルで灰がこぼれ落ちないよう注意しながら掻き出して下さい。炉内に灰が２～３cm残っていると、次の着火の際に火がつきやすくなり、火持ちも良くなるばかりでなく、燃焼室内の保護にもなります。灰受け皿にも２～３㎝灰を残して下さい。
                </p>
                <p><span className="font-bold">警告</span><br />
                  取り出した灰の中には細かい熾きがあったり、灰自身が熱を帯びている場合があります。一度不燃性の容器に移し、完全に冷えていることを確認してから処理して下さい。 また、薪ストーブを使用して残った灰の保管や処理方法を規定している、地方自治体等ございますので、お住まいの市町村役場等にお問い合わせ下さい。
                </p>
              </Accordion>
              <Accordion  title="煙突掃除はどのくらいの頻度で必要でしょうか。また、どこへお願いをすればいいのでしょうか？">
                <p>年に1度、シーズンオフの間にストーブ本体をはじめ、雨仕舞部分、煙道部材（煙突の支持、接続部分、塗装）などの点検と補修を兼ねた、煙突掃除を行って下さい。煙突掃除を定期的の行う理由は薪ストーブの使用によって、煙道内部に蓄積された、煙道内火災のもととなる煤やクレオソートを除去するためです。ストーブ本体等を設置した販売代理店に煙突掃除を依頼して下さい。
                </p>
              </Accordion>
              <Accordion  title="炉内の耐火煉瓦にヒビが入っているのですが？">
                <p>バーミキュライトやシャモッテ煉瓦などは燃焼室内で薪が爆ぜた衝撃などでヒビが発生する場合がありますが、耐火煉瓦本来の機能である、燃焼室内の温度を高めたり、発生した高温状態の熱から本体を保護する役目には支障をきたしておりませんが、 耐火煉瓦等が砕け落ちてしまった場合は、ストーブ本体の躯体である、鋳物やスチールの素材にダメージを与えてしまう恐れがありますので、交換を要します。
                </p>
              </Accordion>
              <Accordion  title="ストーブ本体内部が錆びついているのですが？">
                <p>乾いた薪にも水分は含まれておりストーブ未使用の際は湿度の影響を受けます。
                  また環境によっては結露が生じる場合がございます。24時間365日使用し続ける訳にはいかないのでストーブ未使用時にこれらが原因で錆が発生することがございますが錆を防ぐというよりはこまめにメンテナンスすることで各部を長持ちさせることができます。
                  見えない部分にサビがあるかもしれず薪ストーブの分解には専門知識が必要になることがございますので弊社代理店までご相談ください。</p>
              </Accordion>

            </div>
          </div>
          <div className="side-list">
        <ul className="sticky">
              <li>
                <GoLink to="before" spy smooth duration={800}>
                  購入前に多いご質問
                </GoLink>
              </li>
              <li>
                <GoLink to="after" spy smooth duration={800}>
                  購入後に多いご質問
                </GoLink>
              </li>
            </ul>
          </div>
        </section>
        <section className="bread">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>Q&A</li>
          </ul>
        </section>
      </Layout>
  );

export default FaqPage;
